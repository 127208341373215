<template>
	<div>
		<div class="event">
			<div class="event-bg">
				<div class="event-bg-1">
					<div class="event-footer">
						<button class="btn-go-play" @click="goToLogin"></button>
					</div>
					<div class="event-noti">
						<button class="btn-go-noti" @click="goToNoti('1')"></button>
					</div>
				</div>
				<div class="event-bg-2">
					<div class="event-footer">
						<button class="btn-go-play" @click="goToLogin"></button>
						<button class="btn-go-vote" @click="goToVote"></button>
					</div>
					<div class="event-cap-1"></div>
					<div class="event-cap-2"></div>
					<div class="event-noti">
						<button class="btn-go-noti" @click="goToNoti('2')"></button>
					</div>
				</div>
			</div>			
		</div>
		<div class="event-mobile">
			<div class="event-bg-mobile">
				<div class="event-bg-mobile-1">
					<div class="event-top">				
						<div class="event-top-title">
							<img src="@/assets/img/event/mobile/title-main1.png" style="width: 85.5%" alt="" />
						</div>
					</div>
					<div class="event-body">
						<div class="event-body-content">
							<div class="event-body-image">
								<img src="@/assets/img/event/mobile/event_11.png" style="width: 95vw" alt="" />
							</div>
							<div class="event-body-image" style="margin-top:-12.5%;margin-right:10%">
								<img src="@/assets/img/event/mobile/event_12.png" style="width: 67.5vw" alt="" />
							</div>						
						</div>
					</div>
					<div class="event-bottom">
						<button class="btn-go-play" @click="goToLogin"></button>
					</div>
					<div class="event-noti-mobile">
						<button class="btn-go-noti" @click="goToNoti('1')"></button>
					</div>
				</div>
				<div class="event-bg-mobile-2">
					<div class="event-top">				
						<div class="event-top-title">
							<img src="@/assets/img/event/mobile/title-main2.png" style="width: 85.5%" alt="" />
						</div>
					</div>
					<div class="event-body">
						<div class="event-body-content">
							<div class="event-body-image" style="margin-top: 9.7%;">
								<img src="@/assets/img/event/mobile/event_21.png" style="width: 84vw" alt="" />
								<button class="btn-go-play" @click="goToLogin"></button>
								<div class="event-cap-1"></div>
							</div>
							<div class="event-body-image" style="margin-top: 1.7%;">
								<img src="@/assets/img/event/mobile/event_22.png" style="width: 84vw" alt="" />
								<button class="btn-go-vote" @click="goToVote"></button>
								<div class="event-cap-2"></div>
							</div>
						</div>
					</div>
					<div class="event-noti-mobile">
						<button class="btn-go-noti" @click="goToNoti('2')"></button>
					</div>
				</div>				
			</div>
		</div>
	</div>
</template>

<script>
// import {
// 	FORTMATIC,
// 	BITSKI,
// 	formatChainId,
// 	WALLETCONNECT,
// 	renderNetworkName
// } from '@/features/Common.js'
// import {
// 	bitski,
// 	fortmaticProvider,
// 	walletConnectProvider,
// } from '@/features/Connectors'
// import LogType from '@/features/LogType.js';
// import WalletAPI from '@/features/WalletAPI.js'
// var wAPI = new WalletAPI();

export default {
	name: 'Event',
	components: {
	},
	data() {
	},
	mounted() {
		if(this.$route.redirectedFrom && this.$route.redirectedFrom.path.includes('vote')) {
			this.goToDetailVote("web");
			this.goToDetailVote("mobile");
		}
		else {
			this.scrollPageTo('event-bg-1');
			this.scrollPageTo('event-bg-mobile-1');
		}
	},
	methods: {
		scrollPageTo(navEl) {
			let element = document.querySelector('.' + navEl);
			element.scrollIntoView({ behavior: "smooth" });
		},
		goToDetailVote(type) {
			this.mxShowAlert({
				msg: this.$t('event.comming-soon'),
				callback: null,
			});
			// if(type == "web") {
			// 	this.scrollPageTo('event-bg-2');
			// }
			// else if(type == "mobile") {
			// 	this.scrollPageTo('event-bg-mobile-2');
			// }
		},		
		goToLogin() {
			this.mxShowAlert({
				msg: this.$t('event.comming-soon'),
				callback: null,
			});	
			// this.mxGameStart();
		},
		goToNoti(type) {
			this.mxShowEventNotiModal({				
				isShow: true,
				type: type
			});
		},
		goToVote() {
			this.mxShowAlert({
				msg: this.$t('event.comming-soon'),
				callback: null,
			});
			// this.$router.push({
			// 	name: 'Dao',
			// 	query: {
			// 		param: 'event'
			// 	},
			// })
		},
		// async buyHousingLuckyBoxEvent() {
		// 	this.mxShowAlert({
		// 		msg: this.$t('event.comming-soon'),
		// 		callback: null,
		// 	});			
		// 	// this.mxShowLoading();
		// 	// const data = {
		// 	// 	provider: this.getProvider(),
		// 	// 	network: renderNetworkName(window.localStorage.getItem('currentNetwork')),
		// 	// 	amount: 1,
		// 	// 	account: this.$store.state.userInfo.wallet_addr,
		// 	// 	category1: "mysteryBox",
		// 	// 	category2: "housingLuckyBoxEvent",
		// 	// 	callback: (res) => this.successBuyHousingLuckyBoxEvent(res)
		// 	// }

		// 	// await wAPI.ContractPurchase(data);
		// },
		// successBuyHousingLuckyBoxEvent(res) {
		// 	this.mxCloseLoading();
		// 	if(res.res_code == 200) {
		// 		this.mxShowEventBuyModal({
		// 			isShow: true,
		// 			state: true
		// 		});
		// 		_U.callSaveLog({
		// 			keyIdx : "event_purchase",
		// 			keyValue : 0, 
		// 			landIdx : 0, 
		// 			floor : 0,
		// 			currencyType : "", 
		// 			currencyValue : 0, 
		// 			totalCurrency : 0,
		// 			message : "",
		// 			action : LogType.WEB_EVENT_PURCHASE
		// 		})
		// 	}
		// 	else {
		// 		this.mxShowEventBuyModal({
		// 			isShow: true,
		// 			state: false
		// 		});
		// 	}
		// },
		// getProvider() {
		// 	let provider = null
		// 	const loginBy = window.localStorage.getItem('loginBy')
		// 	switch (loginBy) {
		// 		case FORTMATIC:
		// 			provider = fortmaticProvider.getProvider()
		// 			break
		// 		case WALLETCONNECT:
		// 			provider = walletConnectProvider
		// 			break
		// 		case BITSKI:
		// 			provider = bitski.getProvider()
		// 			break
		// 	}
		// 	return provider
		// },
	},
}
</script>

<style lang="scss" scoped>
.event-effect {
	height: calc(168.75vw + 10.25rem);
	overflow: hidden;
	pointer-events: none;
}

.event-bg {
	position: absolute;
	width: 100vw;
}

.event-bg-1 {
	width: 100vw;
	@include FLEXV(flex-start, center);
	background-image: url('../assets/img/event/web/main-bg1.png');
	background-repeat: no-repeat;
	background-size: cover;	
	height: 56.25vw;
	margin-top: 10.25rem;

	.event-footer {
		height: 5vw;
		width: 100%;
		margin-top: 46.5vw;
		@include FLEX(space-evenly, center);
		.btn-go-play {
			width: 12.5%;
			height: 62.5%;
			cursor: pointer;
			@include SetBgImage(
				url('../assets/img/event/web/btn-play.png')
			);
		}
	}
}

.event-bg-2 {
	width: 100vw;
	@include FLEXV(flex-start, center);
	background-image: url('../assets/img/event/web/main-bg2.png');
	background-repeat: no-repeat;
	background-size: cover;	
	height: 56.25vw;

	.event-cap-1 {
		position: absolute;
		width: 9%;
		height: 16%;
		right: 12.5%;
		top: 20%;
		@include SetBgImage(
			url('../assets/img/event/web/event-cap1.png')
		);
		animation: bubble 0.9s linear 0s infinite alternate, bloop 5s linear 0s infinite alternate;
	}

	.event-cap-2 {
		position: absolute;
		width: 8%;
		height: 14.5%;
		right: 6%;
		top: 34%;
		@include SetBgImage(
			url('../assets/img/event/web/event-cap2.png')
		);
		animation: bubble 0.725s linear 0s infinite alternate, bloop 3.5s linear 0s infinite alternate;
	}

	.event-footer {
		height: 5vw;
		width: 100%;
		margin-top: 48.5vw;
		@include FLEX(center, center);
		.btn-go-play {
			width: 17%;
			height: 62.5%;
			margin-right: 7%;
			cursor: pointer;
			@include SetBgImage(
				url('../assets/img/event/web/btn-play.png')
			);			
		}

		.btn-go-vote {
			width: 17%;
			height: 62.5%;
			margin-left: 7%;
			cursor: pointer;
			@include SetBgImage(
				url('../assets/img/event/web/btn-vote.png')
			);
		}
	}
}

.event-noti {
	position: absolute;
	bottom: 20px;
    right: 40px;
    width: 4.41vw;
    height: 5vw;

	.btn-go-noti {
		width: 100%;
		height: 100%;
		cursor: pointer;
		@include SetBgImage(
			url('../assets/img/event/web/btn-noti.png')
		);
	}
}

.event-noti-mobile {
	position: absolute;
	bottom: 20px;
    right: 30px;
    width: 8.82vw;
    height: 10vw;

	.btn-go-noti {
		width: 100%;
		height: 100%;
		cursor: pointer;
		@include SetBgImage(
			url('../assets/img/event/mobile/btn-noti.png')
		);
	}
}

.event-bg-mobile {
	position: absolute;
	width: 100%;
    height: 100%;
}

.event-bg-mobile-1 {
	width: 100vw;
	@include FLEXV(flex-start, center);
	background-image: url('../assets/img/event/mobile/main-bg1.png');
	background-repeat: no-repeat;
	background-size: cover;	
	height: 177.5vw;

	.event-top {
		margin-top: 7.5%;
		width: 100vw;
		@include FLEX(center, center);
		.event-top-title {
			@include FLEXV(center, center);	
		}		
	}

	.event-body {
		@include FLEXV(center, center);
		margin-top: 3%;
		width: 100%;

		.event-body-content {
			display: contents;
		}
	}

	.event-bottom {
		width: 100%;
		height: 100%;
		margin-bottom: 6%;
    	margin-top: -2%;
		@include FLEX(center, center);
		.btn-go-play {
			left: 33%;
			position: absolute;
			width: 38.5%;
			height: 100%;
			cursor: pointer;
			@include SetBgImage(
				url('../assets/img/event/mobile/btn-play.png')
			);
		}
	}
}

.event-bg-mobile-2 {
	width: 100vw;
	@include FLEXV(flex-start, center);
	background-image: url('../assets/img/event/mobile/main-bg2.png');
	background-repeat: no-repeat;
	background-size: cover;	
	height: 177.5vw;

	.event-top {
		margin-top: 7.5%;
		width: 100vw;
		@include FLEX(center, center);
		.event-top-title {
			@include FLEXV(center, center);	
		}		
	}

	.event-body {
		@include FLEXV(center, center);
		margin-top: 3%;
		width: 100%;

		.event-body-content {
			display: contents;			
		}

		.event-body-image {
			.btn-go-play {
				right: 14%;
    			bottom: 13%;
				position: absolute;
				width: 38.5%;
				height: 15.5%;
				cursor: pointer;
				@include SetBgImage(
					url('../assets/img/event/mobile/btn-play.png')
				);
			}

			.btn-go-vote {
				right: 14%;
    			bottom: 13%;
				position: absolute;
				width: 38.5%;
				height: 15.5%;
				cursor: pointer;
				@include SetBgImage(
					url('../assets/img/event/mobile/btn-vote.png')
				);
			}

			.event-cap-1 {
				position: absolute;
				width: 19%;
				height: 29%;
				right: -6%;
				top: -7.5%;
				@include SetBgImage(
					url('../assets/img/event/web/event-cap1.png')
				);			
				animation: bubble 0.9s linear 0s infinite alternate, bloop 5s linear 0s infinite alternate;
			}

			.event-cap-2 {
				position: absolute;
				width: 15%;
				height: 23.5%;
				left: -6%;
				top: 6%;
				@include SetBgImage(
					url('../assets/img/event/web/event-cap2.png')
				);			
				animation: bubble 0.725s linear 0s infinite alternate, bloop 3.5s linear 0s infinite alternate;
			}
		}
	}
}

.event-mobile {
	display: none;
	width: 100%;
    height: 100%;
}

@include media-max($media_large) { // 1200
	.event-contents {
		.event-body {
			display: none;			
		}

		.event-body-medium {
			@include FLEXV(space-between, center);
			margin-top: 3%;
			width: 70vw;
			& div{
				width: 100%;
				margin-bottom: 3%;
				@include FLEX(space-evenly, center);
			}
		}

		.event-footer {
			height: 7.5vw;
			.btn-go-detail {
				width: 20%;
				height: 55%;
			}
			.btn-go-play {
				width: 20%;
				height: 55%;
			}
		}
	}
}

@include media-max($media_small) { // 768
	.event {
		display: none;	
	}

	.event-mobile {
		display: block;
	}
}

@keyframes bubble {
	0% {margin-top: 0px;}
	33% {margin-left: -5px;}
	66% {margin-right: 2.5px;}
	100% {margin-top: 10px;}
}
@-webkit-keyframes bubble {
	0% {margin-top: 0px;}
	33% {margin-left: -5px;}
	66% {margin-right: 2.5px;}
	100% {margin-top: 10px;}
}

@-webkit-keyframes bloop {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.05, 0.9125, 1);
  }
  40% {
    transform: scale3d(0.925, 1.05, 1);
  }
  50% {
    transform: scale3d(1.025, 0.925, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.0125, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bloop {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.05, 0.9125, 1);
  }
  40% {
    transform: scale3d(0.925, 1.05, 1);
  }
  50% {
    transform: scale3d(1.025, 0.925, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.0125, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>
